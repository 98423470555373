window.onload = function () {
    
    this.interval = setInterval(() => {
        
        let ph = document.getElementById('gigya-textbox-70653334161496020');
        if (ph != null && !(ph?.classList?.contains("phonenum_uk"))) {
           
            clearInterval(this.interval);
            var im = new Inputmask('(999) 999-9999');
            im.mask(ph);
        }
        else if(ph != null && (ph?.classList?.contains("phonenum_uk"))){

            clearInterval(this.interval);
            var im = new Inputmask('99999999999',{"placeholder": ""});
            im.mask(ph);
            $('#gigya-textbox-70653334161496020').on("blur",function(){ 
            var unmaskval =  $("#gigya-textbox-70653334161496020").val().toString().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').length;
		   
            if(unmaskval==0)
            {
            $(".phonenumerror").hide();
            $("#gigya-textbox-70653334161496020").removeClass("errorboxOutline");
            $("#gigya-textbox-70653334161496020").parent().find(".error_icon").show();
            }

            else if(unmaskval==10 || unmaskval==11)
            {
            $(".phonenumerror").hide();
            $("#gigya-textbox-70653334161496020").removeClass("errorboxOutline");
            $("#gigya-textbox-70653334161496020").parent().find(".error_icon").hide();
            }

            else
            {
            $(".phonenumerror").show();
            $("#gigya-textbox-70653334161496020").addClass("errorboxOutline");
            $("#gigya-textbox-70653334161496020").parent().find(".error_icon").show();
            } 
        });
        }
        $("#gigya-dropdown-133586966665091550").change(function () {
            if($(this).val() == "GB +44"){
             $("#country_popup").modal('show');
            }
        });
        $(".Coutrypopupclose").on("click",function(){
            $("#country_popup").modal('hide');
        })

        $("#gigya-dropdown-34478997066767304").change(function () {
            if($(this).val() == "Other")
            {
             $(".createprofile_other").show();
             $("#gigya-textbox-86236342873862300").removeClass("errorboxOutline");
             $(".customerrorjob").hide();
             $("#gigya-textbox-86236342873862300").parent().find(".error_icon").hide();
             $("#gigya-textbox-86236342873862300").val("");
            }
            else{
                $(".createprofile_other").hide();
            }    
        });

        $("#gigya-textbox-86236342873862300").on("keyup blur",function(){
            if(($("#gigya-dropdown-34478997066767304").val()=="Other") && $("#gigya-textbox-86236342873862300").val()=="")
            {   
                $(".customerrorjob").show();
                $("#gigya-textbox-86236342873862300").addClass("errorboxOutline");
                $("#gigya-textbox-86236342873862300").parent().find(".error_icon").show();
            }
            else if(($("#gigya-dropdown-34478997066767304").val()=="Other") && $("#gigya-textbox-86236342873862300").val()!="")
            {   
                $(".customerrorjob").hide();
                $("#gigya-textbox-86236342873862300").removeClass("errorboxOutline");
                $("#gigya-textbox-86236342873862300").parent().find(".error_icon").hide();
            }
       });

       $(".createprofile_continuebtn").click(function(){

        $(".default_error").each(function(){    
            if($(this).val()=="")
            {
                $(this).parent().find(".error_icon").show();
            }
            else
            {
                $(this).parent().find(".error_icon").hide();
            }  
        });

        if(!($(".consent_radiobtn").is(":checked")))
        {
            $(".consent_errorsection").show();
        }  
        else
        {
            $(".consent_errorsection").hide();
        }  
        
        if(($("#gigya-dropdown-34478997066767304").val()=="Other") && $("#gigya-textbox-86236342873862300").val()=="")
        {   
            $(".customerrorjob").show();
            $("#gigya-textbox-86236342873862300").addClass("errorboxOutline");
            $("#gigya-textbox-86236342873862300").parent().find(".error_icon").show();
        }
        else if(($("#gigya-dropdown-34478997066767304").val()=="Other") && $("#gigya-textbox-86236342873862300").val()!="")
        {   
            $(".customerrorjob").hide();
            $("#gigya-textbox-86236342873862300").removeClass("errorboxOutline");
            $("#gigya-textbox-86236342873862300").parent().find(".error_icon").hide();
        }
        else
        {
            $(".customerrorjob").hide();
            $("#gigya-textbox-86236342873862300").removeClass("errorboxOutline");
            $("#gigya-textbox-86236342873862300").parent().find(".error_icon").hide();
        }

    });

    $(".default_error").on("blur",function(){
        if($(this).parent().find(".createprofile_errorcheck").text()!== "")
        {
            $(this).parent().find(".error_icon").show();
        }
        else
        {
            $(this).parent().find(".error_icon").hide();
        }
    }); 

    
    $("body").on("click",".resendemail_btn",function(){
		window.profileEmail = $(".profilemail").text().split("}}")[1];
		
	});

    }, 100);


}
